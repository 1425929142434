import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "menu", "chevron"]
  
  initialize() {
    this.chevronTargets[0].classList.toggle("active", true)
  }
  
  toggle() {
    this.chevronTargets.forEach((el, i) => {
      el.classList.toggle('active')
    })
    
    this.menuTarget.classList.toggle("opened")
  }
}
