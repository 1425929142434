import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["overlay"]
  
  initialize () {
    // run once, when the controller is first instantiated
    // console.log("lightbox initiated")
  }
  
  display (e) {
    // console.log('active')
    document.body.style.overflow = "hidden";
    this.overlayTarget.classList.toggle('active', true);
  }
  
  close (e) {
    // console.log('closed')
    document.body.style.overflow = "auto";
    this.overlayTarget.classList.toggle('active', false);
  }
}