import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "slide", "dot", 'arrow', 'nofn' ]

  initialize() {
    this.showSlide(0)
  }
  
  fwd() {
    this.showSlide(this.index + 1)
  }
  
  rew() {
    this.showSlide(this.index - 1)
  }
  
  pick(e) {
    this.showSlide(parseInt(Array.prototype.indexOf.call(e.target.parentNode.children, e.target)))
  }
  
  showSlide(index) {
    if (index >= this.slideTargets.length) { index = this.slideTargets.length - 1 }
    if (index <= 0) { index = 0 }
    
    this.arrowTargets[0].style.visibility = index == 0 ? 'hidden' : 'visible';
    this.arrowTargets[1].style.visibility = index == (this.slideTargets.length - 1) ? 'hidden' : 'visible';

    this.index = index
    this.slideTargets.forEach((el, i) => {
      el.classList.toggle('current', index == i)
    })
    this.dotTargets.forEach((el, i) => {
      el.classList.toggle('current', index == i)
    })
    let currentlyOn = (index + 1) +'/'+ this.slideTargets.length;
    this.slideTargets[index].dataset.currently = currentlyOn;
  }
}
