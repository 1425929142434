import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["item"]
  
  dragStart(e) {
    this.dragging = e.target
  }
  
  dragOver(e) {
    e.preventDefault()
    e.target.classList.toggle('with-insertion-point', true)
  }
  
  dragLeave(e) {
    e.preventDefault()
    e.target.classList.toggle('with-insertion-point', false)
  }
  
  dropOnto(e) {
    e.preventDefault();
    this.element.insertBefore(this.dragging, e.target);
    var url = new URL(this.data.get("url"));
    this.itemTargets.forEach(fig => url.searchParams.append("ids[]", fig.id) );

    fetch(url)
      .then(res => res.text())
      .then(html => { this.element.innerHTML = html }
    )
  }
  
  openDialog(e) {
    // console.log('->', e.target.tagName)
    if (e.target.tagName == 'FIGURE') {
      document.getElementById('dial' + e.target.id).showModal()
    }
  }
}