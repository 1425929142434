import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "menu", "chevron", "apartment", "table"]
  
  initialize() {
    this.chevronTargets[0].classList.toggle("on", true)
    this.apartmentTarget.innerHTML = "Vitis"
    this.showTable(0)
  }
  
  toggle() {
    this.chevronTargets.forEach((el, i) => {
      el.classList.toggle('on')
    })
    
    this.menuTarget.classList.toggle("opened")
  }
  
  showTable(index) {
    this.tableTargets.forEach((el, i) => {
      el.classList.toggle('current', index == i)
    })
  }
  
  pick(e) {
    this.apartmentTarget.innerHTML = e.target.innerHTML
    this.showTable(parseInt(Array.prototype.indexOf.call(e.target.parentNode.children, e.target)))
    this.toggle()
  }
}