// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("leaflet")
require("siema")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"

require("glightbox/dist/css/glightbox.min.css")
import GLightbox from "glightbox"

function buildGLightBoxById(btnId) {
  var btn = document.getElementById(btnId);
  if (btn) {
    var _urls = JSON.parse(btn.dataset.urls);
    var _elements = [];
    for (var i = 0; i < _urls.length; i++) {
      _elements.push({ 'href': _urls[i], 'type': 'image' } );
    }
    var galleryName = btnId.replace('more_','');
    let arr = galleryName.split('_');
    let capital = arr.map((item, index) => index ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : item.toLowerCase());
    let kebabized = capital.join("");
    // console.log('-->', kebabized + 'Gallery for elements: ', _elements)

    window[kebabized + 'Gallery'] = GLightbox({ elements: _elements, draggable: true });
  }
}

document.addEventListener("turbolinks:load", () => {
  var lightbox = GLightbox({
    touchNavigation: true,
    loop: true,
    zoomable: false,
  });
  
  buildGLightBoxById('more_oil')
  
  buildGLightBoxById('more_honey')
  
  buildGLightBoxById('more_saffron')
  
  buildGLightBoxById('more_organic_garden')
  
  buildGLightBoxById('more_flowers')
  
  buildGLightBoxById('more_cooking_class')
})