import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tab", "panel"]
  
  initialize () {
    this.currentPanel = document.URL.split('#')[1] || this.panelTargets[0].id
    this.idx = this.panelTargets.map(e => e.id).indexOf(this.currentPanel)
    this.tabTargets[this.idx].classList.toggle("active")    
    this.panelTargets[this.idx].classList.toggle("active");
  }
  
  display (e) {
    this.tabTargets.forEach((el, i) => { 
      el.classList.toggle('active', (el.title == e.target.title))
    })
    this.panelTargets.forEach((el, i) => { 
      el.classList.toggle('active', (el.id == e.target.title))
    })
    window.location.hash = '#' + e.target.title
  }
}