import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["txt"]
  
  initialize() {
    this.showTxt(0);
  }
  
  connect() {
    if (this.data.has("refreshInterval")) {
      this.startRefreshing()
    }
  }
  
  disconnect() {
    clearInterval(this.rInterval);
  }
  
  startRefreshing() {
    this.rInterval = setInterval(() => {
      this.fwd()
    }, this.data.get("refreshInterval"))
  }
  
  fwd() {
    this.showTxt(this.index + 1);
  }
  
  showTxt (index) {
    var sz = this.txtTargets.length
    if (index >= sz) { index = 0 }

    this.index = index
    this.txtTargets.forEach((el, i) => {
      el.classList.toggle('current', index == i)
    })
  }
}